<template>
  <div class="back-user-center">
            <div class="top-box">
              <div class="top-title">个人中心</div>
            </div>
            <div class="section-box">
                <i class="el-icon-document section-icon"></i>
                <div class="section-info">
                    <div class="section-title">个人基本资料</div>
                    <div class="section-desc">PERSONAL INFORMATION</div>
                </div>
            </div>
            <el-row class="form-group">
                <el-col :span="8" class="form-item">
                    <div class="label">个人账号</div>
                    <div class="input-box">
                        <el-input v-model="account" 
                                :disabled="true"
                                class="form-control">
                        </el-input>
                    </div>
                </el-col>
                <el-col :span="8" class="form-item">
                    <div class="label">所属部门</div>
                    <div class="input-box">
                        <el-input v-model="department" 
                                :disabled="true"
                                class="form-control">
                        </el-input>
                    </div>
                </el-col>
                <el-col :span="8" class="form-item">
                    <div class="label">个人账号</div>
                    <div class="input-box">
                        <el-input v-model="account" 
                                :disabled="true"
                                class="form-control">
                        </el-input>
                    </div>
                </el-col>
                <el-col :span="8" class="form-item">
                    <div class="label">岗位名称</div>
                    <div class="input-box">
                        <el-input v-model="jobName" 
                                :disabled="true"
                                class="form-control">
                        </el-input>
                    </div>
                </el-col>
                <el-col :span="8" class="form-item">
                    <div class="label">角色</div>
                    <div class="input-box">
                        <el-input v-model="role" 
                                :disabled="true"
                                class="form-control">
                        </el-input>
                    </div>
                </el-col>
                <el-col :span="8" class="form-item">
                    <div class="label">岗位名称</div>
                    <div class="input-box">
                        <el-input v-model="jobName" 
                                :disabled="true"
                                class="form-control">
                        </el-input>
                    </div>
                </el-col>
                <el-col :span="8" class="form-item">
                    <div class="label">绑定银行卡</div>
                    <div class="input-box">
                        <div class="action-link">绑定新银行卡</div>
                        <div class="action-link">解绑</div>
                    </div>
                </el-col>
                <el-col :span="8" class="form-item">
                    <div class="label">二维码</div>
                    <div class="input-box">
                        <div class="action-link">个人二维码</div>
                        <div class="action-link">代理商加盟二维码</div>
                    </div>
                </el-col>
            </el-row>
            <el-divider></el-divider>
            <div class="section-box">
                <i class="el-icon-user section-icon"></i>
                <div class="section-info">
                    <div class="section-title">个人基本信息</div>
                    <div class="section-desc">PERSONAL BASIC INFORMATION</div>
                </div>
            </div>
            <el-row class="form-group">
                <el-col :span="8" class="form-item">
                    <div class="label">
                        <div class="required-label">*</div>
                        用户昵称
                    </div>
                    <div class="input-box">
                        <el-input v-model="userName" class="form-control"></el-input>
                    </div>
                </el-col>
                <el-col :span="8" class="form-item">
                    <div class="label">
                        <div class="required-label">*</div>
                        真实姓名
                    </div>
                    <div class="input-box">
                        <el-input v-model="realName" 
                                :disabled="true"
                                class="form-control">
                        </el-input>
                    </div>
                </el-col>
                <el-col :span="8" class="form-item">
                    <div class="label">
                        <div class="required-label">*</div>
                        手机号码
                    </div>
                    <div class="input-box">
                        <el-input v-model="phone" class="form-control"></el-input>
                    </div>
                </el-col>
                <el-col :span="8" class="form-item">
                    <div class="label">内部邮箱</div>
                    <div class="input-box">
                        <el-input v-model="email" class="form-control"></el-input>
                    </div>
                </el-col>
                <el-col :span="8" class="form-item">
                    <div class="label">入职时间</div>
                    <div class="input-box">
                        <el-date-picker
                            v-model="joinDate"
                            type="date"
                            class="form-control">
                        </el-date-picker>
                    </div>
                </el-col>
                <el-col :span="24" class="form-item">
                    <div class="label"></div>
                    <div class="btn" @click="handleInfoUpdate">保存</div>
                </el-col>
            </el-row>
            <el-divider></el-divider>
            <div class="section-box">
                <i class="el-icon-lock section-icon"></i>
                <div class="section-info">
                    <div class="section-title">修改密码</div>
                    <div class="section-desc">CHANGE PASSWORD</div>
                </div>
            </div>
            <el-row class="form-group">
                <el-col :span="8" class="form-item">
                    <div class="label">
                        <div class="required-label">*</div>
                        旧密码
                    </div>
                    <div class="input-box">
                        <el-input v-model="oldPassword" type="password" class="form-control"></el-input>
                    </div>
                </el-col>
                <el-col :span="8" class="form-item">
                    <div class="label">
                        <div class="required-label">*</div>
                        新密码
                    </div>
                    <div class="input-box">
                        <el-input v-model="newPassword" type="password" class="form-control"></el-input>
                    </div>
                </el-col>
                <el-col :span="8" class="form-item">
                    <div class="label">
                        <div class="required-label" >*</div>
                        确认密码
                    </div>
                    <div class="input-box">
                        <el-input v-model="confirmPassword" type="password" class="form-control"></el-input>
                    </div>
                </el-col>
                <el-col :span="24" class="form-item">
                    <div class="label"></div>
                    <div class="btn" @click="handleChangePassword">保存</div>
                </el-col>
            </el-row>
            <el-divider></el-divider>
            <div class="section-box">
                <i class="el-icon-camera section-icon"></i>
                <div class="section-info">
                    <div class="section-title">机构信息</div>
                    <div class="section-desc">ORGANIZATION INFORMATION</div>
                </div>
            </div>
            <el-row class="form-group">
                <el-col :span="8" class="form-item">
                    <div class="label">中心名称</div>
                    <div class="input-box">
                        <el-input v-model="centerName"
                            :disabled="true"
                            class="form-control">
                        </el-input>
                    </div>
                </el-col>
                <el-col :span="8" class="form-item">
                    <div class="label">真实姓名</div>
                    <div class="input-box">
                        <el-input v-model="centerRealName"
                            :disabled="true"
                            class="form-control">
                        </el-input>
                    </div>
                </el-col>
                <el-col :span="8" class="form-item">
                    <div class="label">学籍省份</div>
                    <div class="input-box">
                        <el-input v-model="province"
                            :disabled="true"
                            class="form-control">
                        </el-input>
                    </div>
                </el-col>
                <el-col :span="8" class="form-item">
                    <div class="label">可用余额</div>
                    <div class="input-box">
                        <el-input v-model="AvailableBalance"
                            :disabled="true"
                            class="form-control">
                        </el-input>
                    </div>
                </el-col>
                <el-col :span="8" class="form-item">
                    <div class="label">保证金</div>
                    <div class="input-box">
                        <el-input v-model="earnestMoney"
                            :disabled="true"
                            class="form-control">
                        </el-input>
                    </div>
                </el-col>
            </el-row>
          </div>
</template>
<script>
export default {
  name: "userCenter",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
        account:"",
        department:"德州高斯教育咨询有限公司(陵城区)",
        jobName:"加盟校规划师",
        role:"加盟校顾问/规划师",
        userName:"",
        realName:"",
        phone:"",
        email:"",
        joinDate:"",
        oldPassword:"",
        newPassword:"",
        confirmPassword:"",
        centerName:"鸿才志愿",
        centerRealName:"鸿才教育有限责任公司",
        province:"辽宁省",
        AvailableBalance:"5000.0",
        earnestMoney:"0"
    };
  },
  mounted(){
      this.initInfo();
  },
  methods: {
    initInfo(){
        let currentUser = this.$store.getters.getAdminInfo;
        this.account = currentUser.username;
        this.userName = currentUser.fullname;
        this.realName = currentUser.fullname;
        this.phone = currentUser.phone;
    },
    handleInfoUpdate(){
        if(!this.userName || !this.phone || this.userName == "" || this.phone == ""){
            this.$message.error("请填写正确信息!");
            return
        }
        if(this.phone.length !== 11){
            this.$message.warning("请填写正确的手机号!");
            return
        }
        let currentUser = this.$store.getters.getAdminInfo;
        var data = { 
            id: currentUser.id,
            fullname: this.userName,
            phone: this.phone
        };
        this.$http.post(this.$apis.updateUserInfo,data).then(r=>{
            if(r.code==200){
                this.$message.success("修改成功");
                //todo
                //更新缓存
            }else{
                this.$message.error(r.message);
            }
        });
    },
    handleChangePassword(){
        if(!this.oldPassword||!this.newPassword||!this.confirmPassword
        ||this.oldPassword=="" || this.newPassword ==""|| this.confirmPassword ==""){
            this.$message.error("密码不能为空!");
            return;
        }
        if(this.newPassword !== this.confirmPassword){
            this.$message.error("两次输入的新密码不一致,请重新输入!");
            return;
        }
        let currentUser = this.$store.getters.getAdminInfo;
        this.$http.post(this.$apis.getUserDetail+"?id="+currentUser.id).then((res) => {
            if (res.data) {
                if(res.data.password == this.oldPassword){
                    this.$http.post(this.$apis.updateUserInfo,{ id: currentUser.id,password: this.newPassword}).then(r=>{
                        if(r.code==200){
                            this.$message.success("修改成功");
                            this.oldPassword = "";
                            this.newPassword = "";
                            this.confirmPassword = "";
                        }else{
                            this.$message.error(r.message);
                        }
                    });
                }else{
                    this.$message.error("旧密码错误,请重新输入!");
                    return;
                }
            }else{
                this.$message.error("内部错误, 请稍后重试");
                return;
            }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.back-user-center {
  width: 100%;
  position: relative;
  .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 10px;
    border-bottom: 1px solid rgba(230, 230, 230, 100);

    .top-title {
      color: rgba(16, 16, 16, 100);
      font-size: 20px;
      font-weight: 700;
      padding-left: 20px;
      position: relative;
    }
    .top-title::after {
      position: absolute;
      display: block;
      content: "";
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      height: 27px;
      width: 3px;
      background-color: #ff450b;
    }
  }
  .section-box {
    display: flex;
    align-items: center;
    padding: 13px 10px;
    color: #646464;
    .section-icon{
        padding: 0px 10px;
        font-size: 25px;
    }
    .section-info{
        .section-title{
            font-size: 18px;
            font-weight: 700;
        }
        .section-desc{
            font-size: 8px;
        }
    }
    
  }
  .form-group{
      padding: 0px 13px;
    .form-item {
      display: flex;
      align-items: center;
      padding: 15px 0;
      .label {
        display: flex;
        justify-content: flex-end;
        color: rgba(100, 100, 100, 100);
        font-size: 14px;
        width: 90px;
        padding-right:10px;
      }
      .input-box {
          display: flex;
          width: calc(100% - 100px);
      }
      .required-label{
            color: rgb(255, 0, 0);
      }
      .action-link{
          font-size: 16px;
          cursor:pointer;
          color: #ff450b;
          padding-right: 20px;
      }
    }
  }
  .form-control{
      width: 100%;
  }
  .btn {
        line-height: 32px;
        width: 60px;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        cursor: pointer;
        font-size: 14px;
    }
}
</style>